'use client'

import './ConfirmCookiesModal.scss'

import cn from 'classnames'
import { useTranslations } from 'next-intl'
import { useState } from 'react'

import {
  getCookiesConfirmation,
  setCookiesConfirmation,
} from '@/src/features/confirm-cookies/storage'
import { isBot, isLightHouse } from '@/src/shared/lib/detectDevice'
import { Button } from '@/src/shared/ui/Button'
import { Text } from '@/src/shared/ui/Text'

export type IConfirmCookiesModalProps = {
  className?: string
}

const ConfirmCookiesModal = ({ className }: IConfirmCookiesModalProps) => {
  const [confirmed, setConfirmed] = useState(false)
  const t = useTranslations('ConfirmCookiesModal')

  if (isLightHouse || isBot) return

  const handleConfirm = (answer: boolean) => {
    setConfirmed(answer)
    setCookiesConfirmation(answer)
  }

  return (
    <section
      className={`${cn('confirm-cookies-modal', {
        [`${className}`]: !!className,
        'confirm-cookies-modal--show': !getCookiesConfirmation(),
        'confirm-cookies-modal--hide': confirmed,
      })}`}
    >
      <div className="confirm-cookies-modal__text">
        <Text weight="m">{t('title')}</Text>
      </div>

      <div className="confirm-cookies-modal__button">
        <Button variant="secondary" onClick={() => handleConfirm(true)}>
          {t('actionYes')}
        </Button>
      </div>
    </section>
  )
}

export default ConfirmCookiesModal
