'use client'

import './Spotify.scss'

import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useKey } from 'react-use'

import useClickAway from '@/src/shared/hooks/useClickAway'
import { isLightHouse } from '@/src/shared/lib/detectDevice'
import { Button } from '@/src/shared/ui/Button'
import { SpotifyIframe } from '@/src/shared/ui/Editor/EditorEmbed/SpotifyIframe'

const Spotify = () => {
  const [isOpen, setDropState] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const [url, setUrl] = useState('')

  function handleToggle(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    setDropState(() => !isOpen)
  }

  useClickAway(() => {
    setDropState(false)
  }, ref)

  useKey('Escape', () => setDropState(false))

  useEffect(() => {
    if (isLightHouse) return

    setUrl('https://open.spotify.com/embed/playlist/2kVDAMVKRT5JD0hBgQNqcQ?utm_source=generator')
  }, [])

  return (
    <section
      ref={ref}
      className={cn('spotify-dropdown', {
        'spotify-dropdown--is-open': isOpen,
      })}
    >
      <a className="spotify-dropdown__toggler" onClick={(e) => handleToggle(e)} href="#">
        <Button tag="div" variant="secondary" squared size="s" iconName="play-icon" />
      </a>

      <div className="spotify-dropdown__body">
        <div className="spotify-dropdown__body-inner">
          <SpotifyIframe
            src={url}
            aspectRatio={{
              width: 345,
              height: 500,
              sm: {
                width: 345,
                height: 500,
              },
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default Spotify
