import { getCookie, setCookie } from 'cookies-next'

import { CONFIRM_COOKIES_COOKIES_NAME } from './config'

export const getCookiesConfirmation = (): boolean => {
  const cookies = getCookie(CONFIRM_COOKIES_COOKIES_NAME)

  if (!cookies) return false

  return JSON.parse(cookies)
}

export const setCookiesConfirmation = (val: boolean): void => {
  setCookie(CONFIRM_COOKIES_COOKIES_NAME, val, { maxAge: 60 * 60 * 24 * 7 })
}
