'use client'

import './IFrame.scss'

import cn from 'classnames'

import { useAspectRatio } from '../../hooks/useAspectRatio'
import useFullscreen from '../../hooks/useFullScreen'
import { IAspectRatio } from '../../model'
import { Icon } from '../Icon'

export type IIFrame = {
  enableFullScreenMode?: boolean
  className?: string
  src: string | null
  allow?: string
  loading?: 'lazy'
  aspectRatio: IAspectRatio
}

const IFrame = ({
  className,
  src,
  aspectRatio,
  allow,
  loading = 'lazy',
  enableFullScreenMode = false,
}: IIFrame) => {
  const { id, styles } = useAspectRatio(aspectRatio)
  const [isFullscreen, { toggleFullscreen }] = useFullscreen(() =>
    document.getElementById(`fullscreen-${id}`),
  )

  return (
    <>
      {styles}

      <section
        className={`${cn('iframe', {
          [`${className}`]: !!className,
        })}`}
      >
        <div id={`fullscreen-${id}`} data-id={id} className="iframe__inner">
          {src && (
            <>
              <iframe frameBorder={0} src={src} allowFullScreen loading={loading} allow={allow} />

              {enableFullScreenMode && (
                <button
                  className="iframe__fullscreen-button"
                  type="button"
                  onClick={toggleFullscreen}
                >
                  <Icon
                    width={25}
                    height={25}
                    name={isFullscreen ? 'fullscreen-close-icon' : 'fullscreen-open-icon'}
                  />
                </button>
              )}
            </>
          )}
        </div>
      </section>
    </>
  )
}

export default IFrame
