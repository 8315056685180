'use client'

import { createContext, useContext } from 'react'
import { useToggle } from 'react-use'

import CartModal from '../ui/CartModal'

const CartModalContext = createContext({
  cartModalIsOpen: false,
  onCartModalChange: (): void => {},
})

export const CartModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [on, toggle] = useToggle(false)

  return (
    <CartModalContext.Provider value={{ cartModalIsOpen: on, onCartModalChange: toggle }}>
      {children}

      <CartModal isOpen={on} onChange={toggle} />
    </CartModalContext.Provider>
  )
}

export const useCartModalContext = () => useContext(CartModalContext)
