import { IAspectRatio } from '@/src/shared/model'

import { IFrame } from '../../IFrame'

type ISpotifyIframe = {
  src: string
  aspectRatio: IAspectRatio
}

export const SpotifyIframe = ({ src, aspectRatio }: ISpotifyIframe) => {
  return (
    <IFrame
      loading="lazy"
      allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      src={src}
      aspectRatio={aspectRatio}
    />
  )
}
