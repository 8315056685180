import './CartModal.scss'

import dynamic from 'next/dynamic'
import { useTranslations } from 'next-intl'

import { Modal } from '@/src/shared/ui/Modal'

export type ICartModalProps = {
  isOpen: boolean
  onChange: (isOpen: boolean) => void
}

const CartModalContent = dynamic(() => import('./CardModalContent').then((mod) => mod.default), {
  ssr: false,
  loading: () => <p>Loading...</p>,
})

const CartModal = ({ isOpen, onChange }: ICartModalProps) => {
  const t = useTranslations('Common')

  return (
    <Modal isOpen={isOpen} onChange={onChange} title={t('cart')}>
      <CartModalContent />
    </Modal>
  )
}

export default CartModal
