import './Container.scss'

import cn from 'classnames'

export type IProps = {
  children: React.ReactNode
  className?: string
  size?: '2xs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'infinity'
  withoutPaddings?: boolean
}

const Container = ({ children, size = 'l', className = '', withoutPaddings = false }: IProps) => {
  return (
    <div
      className={`${cn('container', `container--size-${size}`, {
        [`${className}`]: !!className,
        'container--without-paddings': withoutPaddings,
      })}`}
    >
      {children}
    </div>
  )
}

export default Container
