/* eslint-disable @typescript-eslint/no-explicit-any */
import type { BasicTarget } from '../lib/domTarget'
import { getTargetElement } from '../lib/domTarget'
import getDocumentOrShadow from '../lib/getDocumentOrShadow'
import useEffectWithTarget from '../lib/useEffectWithTarget'
import useLatest from './useLatest'

type DocumentEventKey = keyof DocumentEventMap

export default function useClickAway<T extends Event = Event>(
  onClickAway: (event: T) => void,
  target: BasicTarget | BasicTarget[],
  eventName: DocumentEventKey | DocumentEventKey[] = 'click',
) {
  const onClickAwayRef = useLatest(onClickAway)

  useEffectWithTarget(
    () => {
      const handler = (event: any) => {
        const targets = Array.isArray(target) ? target : [target]
        if (
          targets.some((item) => {
            const targetElement = getTargetElement(item)
            return !targetElement || targetElement.contains(event.target)
          })
        ) {
          return
        }
        onClickAwayRef.current(event)
      }

      const documentOrShadow = getDocumentOrShadow(target)

      const eventNames = Array.isArray(eventName) ? eventName : [eventName]

      eventNames.forEach((event) => documentOrShadow.addEventListener(event, handler))

      return () => {
        eventNames.forEach((event) => documentOrShadow.removeEventListener(event, handler))
      }
    },
    Array.isArray(eventName) ? eventName : [eventName],
    target,
  )
}
