'use client'

import { Button } from '@/src/shared/ui/Button'
import { useCartModalContext } from '@/src/widgets/Cart/context'

const Cart = () => {
  const { onCartModalChange } = useCartModalContext()

  return (
    <Button onClick={onCartModalChange} variant="secondary" squared size="s" iconName="cart-icon" />
  )
}

export default Cart
