import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/src/app/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-stroke-weight\",\"src\":[{\"path\":\"../../../../public/fonts/strokeWeight-140-rotate-12.woff2\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../../../../public/fonts/strokeWeight-140.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../../../public/fonts/strokeWeight-120.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../../public/fonts/strokeWeight-100.woff2\",\"weight\":\"400\",\"style\":\"normal\"}]}],\"variableName\":\"StrokeWeight\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/src/src/app/scss/index.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/layout.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/entities/analytics/ui/InitAnalytics/InitAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/features/confirm-age/ui/ConfirmAgeModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/features/confirm-cookies/ui/ConfirmCookiesModal.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/Container/Container.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/Icon/Icon.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/shared/ui/Image/Image.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/shared/ui/LocalizedLink/LocalizedLink.scss");
;
import(/* webpackMode: "eager", webpackExports: ["CartModalProvider"] */ "/app/src/src/widgets/Cart/context/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeedbackModalProvider"] */ "/app/src/src/widgets/Feedback/context/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/Footer/images/herb-warszawy.svg");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/Footer/ui/Footer.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ShowFeedbackButton"] */ "/app/src/src/widgets/Footer/ui/ShowFeedbackButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/Header/images/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/widgets/Header/ui/Cart/Cart.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/Header/ui/Header.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/widgets/Header/ui/Navigation/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/widgets/Header/ui/Spotify/Spotify.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/src/widgets/MobileNavigation/ui/MobileNavigation.tsx");
