'use client'

import cn from 'classnames'
import { useRef } from 'react'
import { useInViewport } from 'react-in-viewport'

import { ShowFeedbackModalButton } from '../../Feedback/ui/ShowFeedbackModalButton'

export const ShowFeedbackButton = ({ buttonText }: { buttonText: string }) => {
  const viewportRef = useRef<HTMLDivElement>(null)

  const { inViewport } = useInViewport(viewportRef)

  return (
    <>
      <div ref={viewportRef}></div>
      <div
        className={cn('footer__button', {
          'footer__button--show': inViewport,
        })}
      >
        <ShowFeedbackModalButton buttonText={buttonText} />
      </div>
    </>
  )
}
