'use client'

import './MobileNavigation.scss'

import cn from 'classnames'
import { Spiral as Hamburger } from 'hamburger-react'
import { usePathname } from 'next/navigation'
import { useTranslations } from 'next-intl'
import { useEffect } from 'react'
import { useToggle } from 'react-use'

import { usePathname as usePathnameLocalized } from '@/src/app/config/i18n'
import { ColorSemanticThird } from '@/src/shared/config/variables'
import { INavigationKey } from '@/src/shared/model'
import { LocalizedLink } from '@/src/shared/ui/LocalizedLink'

import { ShowFeedbackModalButton } from '../../Feedback/ui/ShowFeedbackModalButton'

export type IMobileNavigationProps = {
  className?: string
  navigation: {
    title: string
    href: string
    key: INavigationKey
  }[]
}

const MobileNavigation = ({ className = '', navigation = [] }: IMobileNavigationProps) => {
  const t = useTranslations('Common')
  const [on, toggle] = useToggle(false)
  const pathname = usePathname()
  const localizedPathname = usePathnameLocalized()

  useEffect(() => {
    if (on) {
      toggle(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  // useEffect(() => {
  //   console.log(on)
  // }, [on])

  return (
    <>
      <div
        className={`${cn('mobile-navigation-button', {
          [`${className}`]: !!className,
          'mobile-navigation-button--opened': on,
        })}`}
      >
        <Hamburger
          color={ColorSemanticThird}
          size={24}
          direction="right"
          toggled={on}
          toggle={toggle}
        />
      </div>

      <div
        className={`${cn('mobile-navigation', {
          [`${className}`]: !!className,
          'mobile-navigation--opened': on,
        })}`}
      >
        <nav className="mobile-navigation__inner">
          <div className="mobile-navigation__list-wrap">
            <ul className="mobile-navigation__list">
              {navigation.map(({ title, href, key }) => (
                <li key={key} className="mobile-navigation__item">
                  <LocalizedLink
                    href={href}
                    className={cn('mobile-navigation__item-link', {
                      'mobile-navigation__item-link--active': localizedPathname.startsWith(href),
                    })}
                  >
                    {title}
                  </LocalizedLink>
                </li>
              ))}
            </ul>

            <div className="mobile-navigation__button">
              <ShowFeedbackModalButton buttonText={t('becomePartner')} />
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default MobileNavigation
